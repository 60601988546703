<template>
  <div class="container">
    <div class="swiper">
      <swiper :imgList="imgList"></swiper>
    </div>
    <div class="title">热点话题</div>
    <div class="content">
      <div class="topics">
        <div class="topics" v-for="(items, index) in topicList" :key="index">
          <div class="content_detail">
            <div class="content_desc">
              <div class="content_title" style="display: flex">
                <div
                  class="content_desc_title"
                  @click="inToDetail(items)"
                  v-for="(item, index) in items.tip"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>

              <h1 @click="inToDetail(items)">{{ items.title }}</h1>
              <span @click="inToDetail(items)">{{ items.desc }}</span>
              <div class="continue_read" @click="inToDetail(items)">
                继续阅读
              </div>
            </div>
            <div class="content_pic" @click="inToDetail(items)">
              <img :src="items.img" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="updateEssay">
        <div class="essayTitle">最新文章</div>
        <div class="news" v-for="(essay, index) in essayList" :key="index">
          <div class="new">{{ essay }}</div>
        </div>
      </div>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" :total="50">
      </el-pagination>
    </div>
    <!-- 尾部 -->
    <div class="footer"></div>
  </div>
</template>
<script>
import swiper from "../components/swiper.vue";
export default {
  components: {
    swiper,
  },
  data() {
    return {
      imgList: [
        {
          id: 1,
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/30/61f6616e2aea6.jpg",
          desc: "走出暴力阴影",
          detail_desc:
            "她可以付出爱，也可以享受爱；她会因不断成长而更成熟，能与人彼此照顾；能自由表达情感，能找到健全的亲密关系。",
          desc_btn: "继续阅读",
        },
      ],
      essayList: [
        "妈不得不说的话",
        "走出暴力阴影",
        "跨文化婚姻辅导",
        "蕾丝的联想",
        "什么是婚姻中的财务虐待",
      ],
      topicList: [
        {
          tip: ["婚姻关系", "热点话题"], //标签
          title: "跨文化婚姻辅导", //标题
          desc: "首先，他们强调了祷告的重要性——恳求上帝帮助他们优先考虑上帝的心意而不是他们自己的文化作用。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/02/01/61f8dee7f1f65.jpg",
        },
        {
          tip: ["热点话题"], //标签
          title: "蕾丝的联想", //标题
          desc: "在大自然中，上帝的细工展现在每朵「小」野花里，当我弯下腰，定睛的瞧，常讶异于它精致的美。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/30/61f6978f3679f.jpg",
        },
        {
          tip: ["婚姻关系", "热点话题"], //标签
          title: "什么是婚姻中的财务虐待", //标题
          desc: "在健康的婚姻中，夫妻都会为双方商定好的预算并以其为目标而共同努力。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/30/61f697f370236.png",
        },
        {
          tip: ["热点话题"], //标签
          title: "减压妙计", //标题
          desc: "把忧虑从关切的事项中区分出来，放下焦虑。如果这件事你无能为力，把它抛诸脑后。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/30/61f698b306108.jpg",
        },
        {
          tip: ["热点话题"], //标签
          title: "做個幸福的人", //标题
          desc: "幸福是我们面对自己心灵的需要，在寻求的过程中，虽然付出并承担，但同时得到满足。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/30/61f69a5acc603.jpg",
        },
        {
          tip: ["热点话题"], //标签
          title: "你是网虫吗?", //标题
          desc: "纵观我们的青少年、大学生和成年人，“网虫”(上网成瘾)可谓比比皆是。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/30/61f6999d2913f.jpg",
        },
        {
          tip: ["婚姻关系", "热点话题"], //标签
          title: "不爱他，却嫁了他", //标题
          desc: "我的再婚生活过得很幸福。我懂得了及时倾泻情绪，真实表达负面情绪。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/30/61f69ae25b508.jpg",
        },
        {
          tip: ["婚姻关系", "热点话题"], //标签
          title: "迎战婚姻暴力", //标题
          desc: "婚姻暴力是相当令人伤痛的。若一直包容，施暴者很难改变，双方受害，子女受害，一代传一代，咒诅永无休止。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/30/61f69b90041c0.jpg",
        },
        {
          tip: ["热点话题"], //标签
          title: "寻找最美的爱", //标题
          desc: "一位唐氏儿男孩的妈妈曾告诉我，与看起来残疾不完全的孩子生活在一起，并且爱他，可向我们展示完全由爱推动的智力进展。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/30/61f6999d2913f.jpg",
        },
        {
          tip: ["亲子关系", "热点话题"], //标签
          title: "科技与品德", //标题
          desc: "你一定还记得从前没有数码相机、iPod、手机、高清电视、笔记本计算机的时候，日子是怎么过的。", //描述
          img: "https://cdn.jsdelivr.net/gh/hututu-tech/IMG-gongfeng@main/2022/01/30/61f69c580f320.jpg",
        },
      ],
    };
  },
  created() {},
  methods: {
    inToDetail(items) {
      console.log(items);
      this.$router.push({
        name: "detail",
        params: { items },
      });
    },
  },
};
</script>
<style  lang='less' scoped>
.swiper {
  object-fit: fill;
  overflow: hidden;
  height: 500px;
}
.container {
  .title {
    padding-left: 160px;
    margin-top: 20px;
    height: 75px;
    text-align: left;
    line-height: 75px;
    background-color: #7ebec5;
    font-weight: 550;
    font-size: 30px;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    padding-left: 160px;
    background-color: #f5f6f7;
    .topics :nth-child(odd) {
      // height: 600px;
      color: #f1f4f5;
      font-size: 30px;
      font-weight: 550;
      // background-color: #f5f6f7;
      .content_detail {
        display: flex;
        margin: 0 auto;
        margin-top: 50px;
        width: 880px;
        height: 300px;
        background-color: #fff;
        .content_desc {
          width: 440px;
          height: 300px;
          box-sizing: border-box;
          padding: 50px;
          display: flex;
          flex-direction: column;
          background-color: #fff;

          h1 {
            margin-top: 20px;
            text-align: left;
            font-size: 26px;
            color: #3a3a3a;
            cursor: pointer;
          }
          span {
            margin-top: 20px;
            text-align: left;
            font-weight: 500;
            font-size: 14px;
            color: #666;
            cursor: pointer;
          }
          .continue_read {
            position: relative;
            margin-top: 20px;
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            color: #257d94;
            cursor: pointer;
          }
        }
        .content_desc_title {
          color: #fff;
          padding: 0 5px;
          // width: 105px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          background-color: #000;
          cursor: pointer;
        }
        .content_desc_title:nth-child(1) {
          margin-right: 10px;
        }
        .content_pic {
          width: 440px;
          height: 300px;
          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
            opacity: 0.7;
            transition: all 0.3s ease;
          }
          img:hover {
            cursor: pointer;
            opacity: 0.5;
          }
        }
      }
    }
    .topics :nth-child(even) {
      color: #f1f4f5;
      font-size: 30px;
      font-weight: 550;
      // background-color: #f5f6f7;
      .content_detail {
        display: flex;
        flex-direction: row-reverse;
        margin: 0 auto;
        margin-top: 50px;
        width: 880px;
        height: 300px;
        background-color: #fff;
        .content_desc {
          width: 440px;
          height: 300px;
          box-sizing: border-box;
          padding: 50px;
          display: flex;
          flex-direction: column;
          background-color: #fff;
          h1 {
            margin-top: 20px;
            text-align: left;
            font-size: 26px;
            color: #3a3a3a;
            background-color: #fff;
            cursor: pointer;
          }
          span {
            margin-top: 20px;
            text-align: left;
            font-weight: 500;
            font-size: 14px;
            color: #666;
            background-color: #fff;
            cursor: pointer;
          }
          .continue_read {
            position: relative;
            margin-top: 20px;
            text-align: left;
            font-size: 16px;
            font-weight: 500;
            color: #257d94;
            cursor: pointer;
            background-color: #fff;
            cursor: pointer;
          }
        }
        .content_desc_title {
          color: #fff;
          padding: 0 5px;
          // width: 105px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          font-size: 14px;
          font-weight: 500;
          background-color: #000;
          cursor: pointer;
        }
        .content_desc_title:nth-child(1) {
          margin-right: 10px;
        }
        .content_pic {
          width: 440px;
          height: 300px;
          img {
            width: 100%;
            height: 100%;
            object-fit: fill;
            opacity: 0.7;
            transition: all 0.3s ease;
          }
          img:hover {
            cursor: pointer;
            opacity: 0.5;
          }
        }
      }
    }
    .updateEssay {
      margin: 50px 0 0 50px;
      width: 220px;
      height: 240px;
      font-size: 18px;
      font-weight: 550;
      display: flex;
      flex-direction: column;
      border-right: 1px solid #dcddde;
      .essayTitle {
        text-align: left;
        margin-bottom: 5px;
      }
      .new {
        margin-bottom: 10px;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        color: #666;
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: center;
    padding: 10vw 0vw;
    background-color: #f5f6f7;
  }
  .footer {
    height: 100px;
    background-color: #5499c7;
  }
  @media screen and (max-width: 1140px) {
    .content {
      width: 100vw;
      padding: 0 20vw 0 10vw !important;
      span {
        display: inline-block;
        width: 60vw !important;
      }
    }
    .title {
      padding-left: 10vw !important;
    }
    .topics {
      height: auto !important;
      .content_detail {
        flex-direction: column-reverse !important;
        margin-top: 30px;
        // padding-top: 50px;
        height: auto !important;
        width: 80vw !important;

        .content_pic {
          height: auto !important;
          width: 80vw !important;
          display: flex;
          justify-content: center !important;
          align-items: center !important;
          img {
            width: 100% !important;
            height: auto !important;
          }
        }
        .content_desc {
          padding: 50px !important;
          width: 80vw !important;
          height: auto !important;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
import { render, staticRenderFns } from "./marriageFamily.vue?vue&type=template&id=382bbf41&scoped=true&"
import script from "./marriageFamily.vue?vue&type=script&lang=js&"
export * from "./marriageFamily.vue?vue&type=script&lang=js&"
import style0 from "./marriageFamily.vue?vue&type=style&index=0&id=382bbf41&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "382bbf41",
  null
  
)

export default component.exports